import { BiSolidChevronsRight } from "react-icons/bi"

function RefundPolicy() {
    return (
        <>
            <section className="term-condton">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-head">
                                <h1 className="thunk-page-top-title">Refund Policy</h1>
                                <h2 className="wp-block-heading">Return & Replacement</h2>
                                <p>In case any product is damaged on receipt, and you don’t accept the packet at the time of the delivery due to a torn or damaged product, a replacement will be provided to you and a refund may also be processed depending upon the facts and circumstances of each case.</p>
                                <p>Products can be returned within 12 hours from the day of delivery.</p>
                                <h2 className="wp-block-heading">Cancellations</h2>
                                <p>For cancellations please call us on 7428518654 or email hello@letsmeatz.com</p>
                                <h2 className="wp-block-heading">PAYMENTS</h2>
                                <p>While availing any of the payment method/s available on the Website, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:</p>
                                <ul>
                                    <li><BiSolidChevronsRight />Lack of authorization for any transaction/s</li>
                                    <li><BiSolidChevronsRight />Exceeding the preset limit mutually agreed by You and between "Bank/s"</li>
                                    <li><BiSolidChevronsRight />Any payment issues arising out of the transaction</li>
                                    <li><BiSolidChevronsRight />Decline of transaction for any other reason/s</li>
                                </ul>
                                <p>You understand, accept and agree that the payment facility provided by CNR Foods Pvt Ltd letsmeatz.com is neither a banking nor financial service but is merely a facilitation providing an electronic, automated online electronic payment, receiving payment through Cash On Delivery, collection and remittance facility for the Transactions on the Website using the existing authorized banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility, CNR FOODS Pvt Ltd is neither acting as trustees nor acting in a fiduciary capacity with respect to the Transaction or the Transaction Price.</p>
                                <p>You, as a Buyer, understand that upon initiating a Transaction You are entering into a legally binding and enforceable contract to purchase the products and/or services using the Payment Facility, and You shall pay the Transaction Price through Your Issuing Bank to the Seller using Payment Facility.</p>
                                <p>You, shall electronically notify Payment Facility Delivery or non Delivery within the time period as provided in Policies. Non notification by You of Delivery or non Delivery within the time period specified in the Policies shall be construed as a deemed Delivery in respect of that Transaction.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default RefundPolicy