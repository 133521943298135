import React from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
import img1 from '../../../assets/img/admclick/img-1.webp'
import img2 from '../../../assets/img/admclick/img-2.webp'
import img3 from '../../../assets/img/admclick/img-3.webp'
import img4 from '../../../assets/img/admclick/img-4.webp'
import img5 from '../../../assets/img/admclick/img-5.webp'
import img6 from '../../../assets/img/admclick/img-6.webp'
import img7 from '../../../assets/img/admclick/img-7.webp'
import img8 from '../../../assets/img/admclick/img-8.webp'
function ServiceList() {
  return (
    <>
      {/* <section className="serviceList">
        <div className="container">
          <div className="serviceItemInfo">
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiChestnutLeaf className="ruppee" />
              </div>
              <div className="serviceItemText">
                <h5>Field Crops</h5>
                <p>Field Crops</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiFruitBowl className="wallet" />
              </div>
              <div className="serviceItemText">
                <h5>Fruit Seeds</h5>
                <p>Get up to 60% Off</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiTomato className="mobile" />
              </div>
              <div className="serviceItemText">
                <h5>Vegetable Seeds </h5>
                <p>on Vegetable</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <GiFertilizerBag className="offer" />
              </div>
              <div className="serviceItemText">
                <h5>fertilizer</h5>
                <p>Check Now</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="service-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="sevi">
                <div className="sevi-img">
                  <img src={img1} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Electronics</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi sevi--2">
                <div className="sevi-img">
                  <img src={img2} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">TVs / Video</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi">
                <div className="sevi-img">
                  <img src={img3} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Video games</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi sevi--2">
                <div className="sevi-img">
                  <img src={img4} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Cameras & Photo</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi">
                <div className="sevi-img">
                  <img src={img5} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Cell Phones</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi seci-2">
                <div className="sevi-img">
                  <img src={img6} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Sports & Outdoors</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi seci-2">
                <div className="sevi-img">
                  <img src={img7} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Apparel</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sevi seci-2">
                <div className="sevi-img">
                  <img src={img8} alt="" />
                </div>
                <div className="adam_description text-center">
                  <a href="#">Car Electronics</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
