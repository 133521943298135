import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery, useGetMenuListQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";

import s1 from '../../../assets/img/kaira/MP000000011492375_658Wx734H_202202051828541.webp'
import s2 from '../../../assets/img/kaira/240K1FHPG-LE_BODYVEW_ENLRES.jpg'
import s3 from '../../../assets/img/kaira/o1582197638m46.jpg'
import s4 from '../../../assets/img/kaira/collection1.jpg'
import s5 from '../../../assets/img/kaira/collection3.jpg'
import s6 from '../../../assets/img/kaira/0612478001474118021.jpg'
import s7 from '../../../assets/img/kaira/-473Wx593H-465353051-silver-MODEL.avif'
import s8 from '../../../assets/img/kaira/enchanted-crystal-bracelet-bracelets-blingvine-929582_750x.webp'
import s9 from '../../../assets/img/leatzmeat/service/s9.png'
import s10 from '../../../assets/img/leatzmeat/service/s10.png'
import s11 from '../../../assets/img/leatzmeat/service/s11.png'
import s12 from '../../../assets/img/leatzmeat/service/s12.png'

import { featuredDB } from "../../../rki-data/category";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

function ProductsCategory() {
  // const { data, isLoading, error } = useGetCategoriesQuery();
  // console.log('category---', data)

  const [data, setdata] = useState(null)

  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/category/filter`, { withCredentials: true })
    setdata(res.data)
  }

  useEffect(() => {
    getData()
  }, [])


  const categories = {
    title: "Shop by categories",
    subTitle: "Freshest meats and much more!",
    categData: [
      { id: "1", imgUrl: s1, catTitle: "Today Deals" },
      { id: "2", imgUrl: s2, catTitle: "chiken" },
      { id: "3", imgUrl: s3, catTitle: "Fish & Seafood" },
      { id: "4", imgUrl: s4, catTitle: "Mutton" },
      { id: "6", imgUrl: s6, catTitle: "Parwans" },
      { id: "5", imgUrl: s5, catTitle: "Ready to Cooks" },
      { id: "7", imgUrl: s7, catTitle: " Eggs" },
      { id: "8", imgUrl: s8, catTitle: " Spread" },
      { id: "9", imgUrl: s9, catTitle: " Cold Cuts" },
      { id: "10", imgUrl: s10, catTitle: " Biryani & Kebab" },
      { id: "11", imgUrl: s11, catTitle: " Plant Based Meat" },
      { id: "12", imgUrl: s12, catTitle: "Meat Masala" },
    ]
  }

  const { t } = useTranslation()
  // console.log(data);
  return (
    <>
      <section className="productsCategorySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content fisherman-content-2 mb-3">
                <h3>{t('SHOP BY CATEGORIES')}</h3>
                <h2 className="text_1">{t(' jewellery Butterfly Shape pendant chain necklace for women and girls Gold-plated Plated Alloy Necklace.')}!</h2>
              </div>
            </div>

            {/* {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <h4 style={{ textAlign: "center", color: "red" }}>Server Error</h4>} */}

            {data && data?.map((item, i) => {
              return <div key={item?.title?._id} className="col-lg-3 col-md-6 col-sm-12">
                <div className="productsCategoryItem">
                  {/* <div className="categoryHeader">
                    <h5 className="title">{item.catTitle}</h5>
                    <Link to={`/product/category/${item._id}`}>view all </Link>
                  </div> */}
                  <div className="categoryBody">
                    <div className="singleProducts singleProducts-2">
                      <Link className="single-link itg-hover-section" to={`/product/category/${item?.title?._id}`} >
                        {
                          item?.title?.icon?.url ? <img src={item?.title?.icon?.url} alt="Product" /> : <img src={''} alt="Product" />
                        }
                        <p> Flower seeds</p>
                        <span className="sale">Sale</span>

                      </Link>
                    </div>
                    <div className="categoryHeader text-center">
                      <h5 className="title">
                        <Link to={`/product/category/${item?.title?._id}`}>
                          {item?.title?.name}
                        </Link>
                      </h5>
                      {/* <Link to={`/product/category/${item._id}`}>view all </Link> */}
                    </div>
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[2].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[3].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[1].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;

