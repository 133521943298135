import axios from "axios";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { base_url } from "../../server";

function OtpLogin(props) {


    const [show, setShow] = useState(true)
    const [logIn, setLogIn] = useState({
        entity: ''
    })
    const [otp, setSetOtp] = useState({
        token: '',
        otp: ''
    })
    const navigate = useNavigate()


    const handleShow = () => {
        if (show) {
            setShow(!show)
        } else {
            setShow(!show)
        }


    }


    const handleLogin = (e) => {
        const clone = { ...logIn }
        const val = e.target.value
        const name = e.target.name
        clone[name] = val
        setLogIn(clone)
    }

    const handleOtp = (e) => {
        const clone = { ...otp }
        const val = e.target.value
        const name = e.target.name
        clone[name] = val
        setSetOtp(clone)
    }

    const handleClickOtp = async () => {
        try {
            const res = await axios.post(`${base_url}user/verifyContact`, logIn)
            console.log(res.data.token);
            handleShow()
            window.localStorage.setItem("otptoken", res?.data.token);
        } catch (error) {

        }
    }

    const otpVerify = async () => {
        try {
            const res = await axios.post(`${base_url}user/verifyOtp`, { token: window.localStorage.getItem("otptoken"), otp: otp.otp })
            props.onHide()
            window.localStorage.setItem("isLogin", true);
            window.localStorage.setItem("token", res?.data.token);
            navigate("/");
        } catch (error) {
            alert(error)
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login / Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="entity" value={logIn.entity} onChange={handleLogin} />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <button type="button" onClick={handleClickOtp} className="btn btn-primary">GET OTP</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>Verify OTP</h2>
                            <p>Verify Mobile Number 6206958466 <Link to="#" onClick={() => handleShow()}>Change</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Enter OTP</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="otp" value={otp.otp} onChange={handleOtp} />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <button type="button" onClick={otpVerify} className="btn btn-primary">Verify</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default OtpLogin