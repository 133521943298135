import React, { useState } from "react";
import fertilizer3 from "../../../../assets/img/products/3.jpg";
import Rating from "../../../../shared/rating/Rating";

import fresh1 from '../../../../assets/img/kaira/today/offer-image-1.cd7f74192f8a678a0ef2.png'
import fresh2 from '../../../../assets/img/kaira/today/offer-image-1.cd7f74192f8a678a0ef2.png'
import fresh3 from '../../../../assets/img/kaira/today/offer-image-1.cd7f74192f8a678a0ef2.png'


import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

import { Link } from "react-router-dom";
import {
  setTodayDeal,
  useGetProductDetailQuery,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { featuredDB } from "../../../../rki-data/category";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";


const dataToday = [
  { _id: '1', img: fresh1 },
  // { _id: '2', img: fresh2 },
  // { _id: '3', img: fresh3 },
  // { _id: '4', img: fresh1 },
]


function TodayDealsProduct({ data, isLoading, error, handleShow }) {
  const curr = window.localStorage.getItem('currencySym')
  const currencySymbol = curr ? curr : 'ZK'

  const dispach = useDispatch()

  // useEffect(() => {
  //   dispach(setTodayDeal(data))
  // }, [data])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  // counter timner

  const COUNTDOWN_TARGET = new Date('2023-09-30T23:59:59')

  const getTimeLeft = () => {
    const totalTimeLeft = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hour = Math.floor(totalTimeLeft / (1000 * 60 * 60) % 24);
    const minute = Math.floor(totalTimeLeft / (1000 * 60) % 60);
    const second = Math.floor(totalTimeLeft / (1000) % 60);
    return { days, hour, minute, second }
  }

  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft(getTimeLeft())
  //   }, 1000)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [])
  const { t } = useTranslation()
  return (
    <>

      <div className="col-lg-6">
        <div className="pd-block tm-offer-content">
          <h6>Super deal of the Month</h6>
          <h1>Brand ear ring on <br /> <span>INR 250</span> only</h1>
        </div>
        <div className="timer">
          <div className="tm-countdown" data-countdown="2020/10/12">
            <div className="tm-countdown-pack tm-countdown-day">
              <h2 className="tm-countdown-count">
                0</h2>
              <h6>
                Days
              </h6>
            </div>
            <div className="tm-countdown-pack tm-countdown-hour">
              <h2 className="tm-countdown-count">
                0
              </h2>
              <h6>
                Hours
              </h6>
            </div>
            <div className="tm-countdown-pack tm-countdown-minutes">
              <h2 className="tm-countdown-count">
                00
              </h2>
              <h6>
                Minutes
              </h6>
            </div>
            <div className="tm-countdown-pack tm-countdown-seconds">
              <h2 className="tm-countdown-count">
                00
              </h2>
              <h6>
                Seconds
              </h6>
            </div>
          </div>
          <a href="#" className="tm-button">Shop now</a>


          {/* <ul>
            {Object.entries(timeLeft).map((el) => {
              const label = el[0]
              const value = el[1]
              return (
                <li key={label}>
                  <span>{value}</span>
                  {label}
                </li>
              )
            })}
          </ul> */}
        </div>
      </div>
      <div className="col-lg-6 order-1 order-lg-2">
        {/* <Slider {...settings}> */}
        {isLoading ? (
          <div className="loaderIcon">
            <BiLoaderAlt />
          </div>
        ) : null}
        {dataToday?.map((item) => {
          // console.log('todayDeal', item);
          return (
            // <div className="row" key={item._id}>
            //   {/* <div className="featuredInfo col-lg-12">
            //       <div className="featuredFigure">
            //         <div className="featuredImg-2">
            //           <Link to={`/product/${item._id}`}>
            //             {item?.variations[0]?.mainImage_url.url ? <img src={item?.variations[0]?.mainImage_url.url} alt="Product" /> : <img src={fertilizer3} alt="Product" />}
            //           </Link>
            //           <div className="quickView quickView-2">
            //             <ul>
            //               <li className="viewProduct">
            //                 <button
            //                   className="quick_view_btn"
            //                   onClick={(e) => {
            //                     handleShow(item._id)
            //                   }}
            //                 >
            //                   <FiSearch />
            //                 </button>
            //               </li>
            //               <li className="addProduct">
            //                 <Link to="/products">
            //                   <GrAdd />
            //                 </Link>
            //               </li>
            //             </ul>
            //           </div>
            //         </div>
            //         <ul className="hotList">
            //           <li>
            //             <Link to="/products">Sale</Link>
            //           </li>
            //           <li>
            //             <Link to="/products">-17%</Link>
            //           </li>
            //         </ul>
            //       </div>
            //       <div className="featuredContent">
            //         <h5>
            //           <Link to={`/product/${item._id}`}>{item.name}</Link>
            //         </h5>
            //         <Rating />
            //         <div className="rateDigit">
            //           <span className="cross">{currencySymbol} {item?.[0]?.variations[0]?.mrp}</span>
            //           <span className="currentPrice">{currencySymbol} {item?.[0]?.variations[0]?.sale_rate}</span>
            //         </div>

            //         <div className="buyNowInfo">
            //           <Link className="btn btn-danger addCart" to={`/product/${item._id}`}
            //           >
            //             View Detail
            //           </Link>
            //           <Link
            //             to={`/product/${item._id}`}
            //             className="btn btn-primary buyNow"
            //           >
            //             <BsFillCartFill /> Buy Now
            //           </Link>
            //         </div>
            //       </div>
            //     </div> */}


            // </div>
            <div className="freshMeat-2" key={item._id}>
              <div className="freshMeat tm-offer-image">
                <a href="#">
                  <img src={item?.img} alt="" />
                </a>
              </div>
            </div>
          );
        })}

        {error && (
          <div className="alertMsg mb-4" role="alert">
            {" "}
            No Data Found{" "}
          </div>
        )}


        {/* </Slider> */}
      </div>



      {/* {isLoading ? (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      ) : null}
      {featuredDB?.slice(0, 4)?.map((item) => {
        console.log('hiuhjuhk', item);
        return (
          <div className=" col-lg-3 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo">
              <div className="featuredFigure">
                <div className="featuredImg">
                  <Link to={`/product/${item._id}`}>
                    {item?.url ? <img src={item?.url} alt="Product" /> : <img src={fertilizer3} alt="Product" />}

                  </Link>
                  <div className="quickView">
                    <ul>
                      <li className="viewProduct">
                        <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item._id)
                          }}
                        >
                          <FiSearch />
                        </button>
                      </li>
                      <li className="addProduct">
                        <Link to="/products">
                          <GrAdd />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="hotList">
                  <li>
                    <Link to="/products">Sale</Link>
                  </li>
                  <li>
                    <Link to="/products">-17%</Link>
                  </li>
                </ul>
              </div>
              <div className="featuredContent">
                <h5>
                  <Link to={`/product/${item._id}`}>{item.name}</Link>
                </h5>
                <Rating />
                <div className="rateDigit">
                  <span className="cross">{currencySymbol} {item?.[0]?.variations[0]?.mrp}</span>
                  <span className="currentPrice">{currencySymbol} {item?.[0]?.variations[0]?.sale_rate}</span>
                </div>

                <div className="buyNowInfo">
                  <Link className="btn btn-danger addCart" to={`/product/${item._id}`}
                  >
                    View Detail
                  </Link>
                  <Link
                    to={`/product/${item._id}`}
                    className="btn btn-primary buyNow"
                  >
                    <BsFillCartFill /> Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          {" "}
          No Data Found{" "}
        </div>
      )} */}

    </>
  );
}

export default TodayDealsProduct;
