import ring from "../../../../assets/img/kaira/banner-image-1.jpg";
import necklace from "../../../../assets/img/kaira/banner-image-2.jpg";
import earings from "../../../../assets/img/kaira/banner-image-3.jpg";
function SubProductItem() {
    return (
        <>
            {/* Single Banner */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <a href="#" className="tm-banner">
                    <img src={ring} alt="banner image" />
                </a>
            </div>
            {/* Single Banner */}

            {/* Single Banner */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <a href="#" className="tm-banner ">
                    <img src={necklace} alt="banner image" />
                </a>
            </div>
            {/* Single Banner */}

            {/* Single Banner */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <a href="#" className="tm-banner">
                    <img src={earings} alt="banner image" />
                </a>
            </div>
            {/* Single Banner */}
        </>
    )
}
export default SubProductItem