import React from "react";

import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                <img src={a1} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="containt-about">
                <div class="sec-title">
                  <span>About Us</span>
                  <h2>We Provide Best Meat</h2>
                </div>
                <div class="text-about">
                  <p>The three founders of Let’s Meatz – Nitin Poddar (Digital Marketer), Chiranjeet (Software Engineer), Rahul Kumar (Bid Manager)– had a vision to bring delicious and nutritious fresh meat products to everyone’s plate. With a passion for providing high-quality food that is both sustainable and affordable, they decided to start their own business in the field of fresh meat production and delivery.</p>
                  <p>They carefully sourced their ingredients from local farmers and suppliers who used humane and ethical practices, as well as sustainable methods of farming. This allowed them to produce premium-quality products with a focus on nutrition, safety, and sustainability.</p>
                  <p>Since then, Let’s Meatz has a vision to grow exponentially, now producing fresh, hygienic chicken products and seafood. We use only the freshest ingredients, sourcing only from farms and suppliers who meet their strict standards for quality and sustainability. Our production process is extremely efficient and hygienic, ensuring the highest level of food safety standards are met.</p>
                </div>
                <ul class="list-1 clearfix">
                  <li><TiTick className="icon-1" />100% Organic Meat</li>
                  <li><TiTick className="icon-1" />Payment Securation</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <h3 className="subtitle">OUR STORY</h3>
          <p className="lead">
            {data?.about_company}
          </p> */}
          {/* <p>
            Our passion lies in the upliftment of farming communities; an unhindered commitment since establishment. Our promoters identified three challenges that supply chains in Africa faced and created a globalized business model around solutions addressing these obstacles.
          </p> */}

        </div>
      </div>
    </>
  );
}

export default AboutStory;
