import SubProductItem from "./subProductItem/SubProductItem"

function SubProduct() {
    return (
        <>
            <div className="tm-section tm-banners-area my-2">
                <div className="container">
                    <div className="row mt-30-reverse">
                        <SubProductItem />
                    </div>
                </div>
            </div>
        </>
    )
}
export default SubProduct