import { BiSolidChevronsRight } from "react-icons/bi"

function ShipingPolicy() {
    return (
        <>
            <section className="term-condton">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-head">
                                <h1 className="thunk-page-top-title">SHIPPING & DELIVERY POLICY</h1>
                                <h2 className="wp-block-heading">Delivery of products</h2>
                                <p>Purchases are shipped from our own or third-party warehouses/ stores or offices from various points in India. Our endeavor is to make the products reach you within 12 hours from receipt of order and payment depending on the address of delivery. Order deliveries will be made by our own staff or third party courier and logistics companies or Postal Services usually between 09 am – 9 pm Monday–Sunday.</p>
                                <p>Goods will need to be signed for upon delivery. We take no responsibility for goods signed by an alternative person other than the person ordering the product at the address indicated at the time of the order. Since the transactions are authorized by the cardholder, we do not take responsibility for incorrect addresses provided at the time of placing the order. We are not responsible for damage to products after delivery.</p>
                                <p>All claims for shortages or damages must be reported to customer service on the day of delivery through the contact us page on the web store. Also, the said shortage of goods needs to be highlighted and signed on the Proof of delivery copy and returned to the delivery person.</p>
                                <h2 className="wp-block-heading">Delivery Charges</h2>
                                <p>Shipping and handling rates may vary based on product, packaging, size, volume, type, and other considerations. The shipping and handling charges are given at the time of check out and consumers will know about this before making payments.</p>
                                <ul>
                                    <li><BiSolidChevronsRight />CNR Foods Pvt Ltd</li>
                                    <li><BiSolidChevronsRight />Sophisticated simplicity for the independent mind.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ShipingPolicy